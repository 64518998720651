// export const BACKEND_BASE_URL = "http://192.168.1.206:8008/";
// export const BACKEND_BASE_URL = "http://192.168.0.223:8008/";
// export const BACKEND_BASE_URL = "http://192.168.1.227:8008/";
// export const BACKEND_BASE_URL = "http://192.168.1.224:8000/";
// export const BACKEND_BASE_URL = "http://192.168.0.109:8008/";
// export const BACKEND_BASE_URL = "http://192.168.1.10:8008/";
// export const BACKEND_BASE_URL = "http://192.168.1.51:8000/";
// export const BACKEND_BASE_URL = "http://192.168.1.59:8000/";
// export const BACKEND_BASE_URL = "http://192.168.1.76:8000/";
// export const BACKEND_BASE_URL = "http://192.168.151.171:8008/";
export const BACKEND_BASE_URL = "https://invmfg.com/api/";
// export const BACKEND_BASE_URL = "http://localhost:8000/";
